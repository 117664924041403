@import "variables/colors.scss";
@import "variables/brand-settings-fluke-health.scss";

%group-header{
  background-color: $yellow;
  color: $gray;
  padding: .5em;
}



/*Admin theme overrides*/

/*Dashboard main page*/
.view-display-id-page_5 {
  .view-header{
  display: flex;
  flex-wrap: wrap;
    h2{
      flex: 0 1 100%;
    }
  }
}
.view-display-id-page_5 .view-header h2{
  flex: 0 1 100%;
}

.view-display-id-page_5 .view-header .section{
  display: inline-block;
  flex: 0 1 33.3%;
}
.view-display-id-page_5 .view-header .section p{
  display: flex;
  flex-wrap: wrap;
}

.view-display-id-page_5 .view-header .section a.button{
  display: inline-block;
  flex: 0 1 35%;
}

.view-admin-views.view-display-id-page .views-submit-button{
  display: block;
  clear: both;
}
.form-item-fields-on-off{
  display: inline-block;
  margin-right: 2em;
}

.form-item-fields-on-off .form-checkboxes .form-item{
  display: inline;
  margin-right: 1em;
}
.view-admin-views.view-display-id-page .views-submit-button .views-exposed-form .views-exposed-widget .form-submit{
  margin-top: 0;
  float: right;
}
/*Product Display form*/

.filter-wrapper{
  display: none;
}
.node-product_display-form{

  .two-column{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    h3 {
      flex: 0 1 100%;
     @extend %group-header;

    }

    .column-group{
      flex: 0 1 48%;
      display: inline-block;
    }


  }
  .three-column{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    h3 {
      flex: 0 1 100%;
      @extend %group-header;
    }

    .column-group{
      flex: 0 1 30%;
      display: inline-block;
    }

  }
  .fieldset-legend{
    position: relative;
  }

  .fieldset-wrapper{

    .field-group-htabs{
      width: 100%;
      margin-right: 2em;
      padding-right: 1em;
    }

    .fieldset-wrapper{
      display: block;
      width: 100%;
      margin-right: 1.5em;
      padding-right: 1em;

    }
  }

  .form-type-link-field {
    .link-field-subrow{
    margin-bottom: 1em;
    }
    .link-field-column {
      float: none;
    }
  }
  .field-name-field-certifications,
  .field-name-field-product-status{

    .form-item.form-type-checkbox{
      width: 20%;
      display: inline-block;
    }
  }
  table th{
    text-transform: none;
  }
  .ief-form{
    .fieldset-wrapper{

      .form-item-field-call-to-action-en-us-form-status,
      .form-wrapper{
        width: 100%;
      }
    }
  }

  .field-name-field-teaser-image,
  .field-name-field-slideshow-images,
  .field-name-field-key-features-image,
  //.field-name-field-key-features,
  .field-name-field-product-type,
  .field-name-field-product-brand,
  .field-name-field-part-number,
  .field-name-field-pcat-id{
    width: 48%;
    display: inline-block;
    vertical-align: top;


  }
  .field-name-field-key-features-image,
  .field-name-field-pcat-id{
    margin-left: 1em;
  }
  .field-name-field-notes{
    .form-textarea{
      height: 500px;
    }
     }

  .group-titles{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    h3{
      flex: 0 1 100%;
      @extend %group-header;
    }
    div{
      display: inline-block;
      flex: 0 1 30%;

    }
  }

}

.node-product_display-form .field-name-field-parent-product-reference,
.node-product_display-form .field-name-field-model-description,
.field-name-field-model-number,
.form-item-title,
.field-name-field-fluke-part-number,
.field-name-field-pomona-category,
.field-name-field-key-features-multi,
.field-name-field-datasheet{
  width: 45%;
  display: inline-block;
  margin-right: 2em;
  vertical-align: top;
}
.checkall-edit-field-product-type-und{

}

.checkall-edit-field-product-type-und .form-item{
  display: inline-block;
  width: 20%;
}


.node-product_display-form .group-voltage-fields,
.node-product_display-form .group-model-details {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

}
.node-product_display-form .group-voltage-fields h3,
.node-product_display-form .group-model-details h3{
  flex: 0 1 100%;
}

.node-product_display-form .group-voltage-fields .field-type-taxonomy-term-reference,
.node-product_display-form .group-model-details .field-type-taxonomy-term-reference{
  display: inline-block;
  flex: 0 1 45%;
}



.filter-wrapper.form-wrapper {
  width: 100%;

}
.filter-guidelines{
  //display: none!important;
}
.field-name-field-feature-specifications,
.field-name-field-comparison-specifications{

  .paragraphs-item-type-specification{
    td{

      > div:first-child{
        display: none;
      }
      .form-wrapper{
        display: inline-block;
      }
      div{
        vertical-align: top;
      }

      .field-name-field-specification-type,
      .field-name-field-specification-category,
      .field-name-field-specification-model,
      .field-name-field-specification-attribute,
      .field-name-field-specification-value,
      .form-actions{
        width: 30%;
        padding-right: 2em;
      }
      .form-actions{
        width: 20%;
        padding-left: 2em;
      }


    }
  }
}

.role-editor{
  .form-item-title,
  .admin-only{
    display: none;
  }

  #edit-field-h1-title,
  #edit-field-category,
  #edit-field-current-site-url{
    display: inline-block;
    width: 30%;
    margin-right: 1em;
    vertical-align: top;
  }

  .group-original-summary,
  .group-original-features,
  .group-original-overview{
    background: $gray-lightest;
    h3{
      background-color: transparent;
    }
  }


}
.view-display-id-page_15{

}
.view-display-id-page_15{
  td{
    width: 7%;
    vertical-align: top;
  }
td.views-field-field-preview-summary-et{
  width: 12%;
}
td.views-field-field-summary-et{
  width: 20%;
}
td.views-field-field-key-features{
 width: 30%;
}
}

.view-fbc-admin-products{
  .view-display-id-page_16{
    th, td{
      width: 10%;
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3){
        width: 7%
      }
      &:nth-child(6),
      &:nth-child(7),
      &:nth-child(8){
        width: 20%;
      }
    }
  }

}

.paragraphs-item-type-specification-item{
  td:nth-child(2){
    display: flex;
    flex-wrap: wrap;
  }
  .form-wrapper{
    width: 100%;
    display: none;
  }
  .field-name-field-specification-attribute{
    display: inline-block;
    width: 25%;
    padding-right: 1em;
  }
  .field-name-field-specification-value{
    display: inline-block;
    width: 65%;
    padding-right: 1em;
  }
  .form-actions{
    display: inline-block;
    width: 5%;
    padding-right: 1em;
  }
}
.field-name-field-general-specifications{
  label{
    background: $color-pop;
  }
}
.paragraphs-item-specification-grid{
  .field-name-field-specification-items{
    .field-items{
      display: flex;
      width: 100%;
      .field-item{
        display: inline-block;
        .field-name-field-specification-set-header{     //each column header
          border: 1px solid $gray-lighter;
          padding: .25em;
        }
        .paragraphs-item-specification-set{
          .field-name-field-specification-items{
            .field-items{

              display: block;
              .field-item{
                display: block;



                .paragraphs-items-field-specification-items{
                  background: purple;
                }

              }
            }

          }
        }

      }
    }
  }

}

.page-admin-manage-content-products-editor-review{
  .group-header{
    margin-bottom: 1em;
  }
  .views-row{
    padding-bottom: 2em;
    margin-bottom: 2em;
    border-bottom: 2px solid $gray-lighter;

    .field-name-field-current-site-url,
    .field-name-field-editor{
      display: inline-block;
      width: 48%;
      .field-label,
      .field-items{
        display: inline-block;
        padding-right: 1em;
      }
    }
    .field-name-field-editor{
      text-align: right;
    }
      .field-name-field-voc-statement{
      p{
        font-size: 1rem;
        font-weight: bold;
        font-style: italic;
      }
    }
    .field-name-field-product-overview{
      > .field-items{
        > .field-item{
          padding: 1em;
          border-bottom: 1px dashed $gray-lightest;
          margin-bottom: 1em;


        }

      }
    }
    >.group-right{
      padding: 3em;
      font-size: .7rem;
      background: transparentize($gray-light, .5);
    }

  }
  > .group-footer{
    margin-top: 1em;
  }
  .field-label{
    color: $gray-light;
  }
}

.view-mode-editor_preview{
 .group-left{
   width: 48%;
   padding-right: 2em;

 }
  .group-right{
    width: 48%;
    padding-left: 2em;
  }
}

  .page-review-content-products-status {
    table {
      margin-bottom: 2em;
      background: none;
      tr,td{

        background: none;
        height: 50px;
        overflow: hidden;
          border-collapse: collapse;
          border: 1px solid whitesmoke;
      }
      th,
      th a{
        color: black;
      }
      td{
        max-width: 50px;
        max-height: 50px;
        overflow: hidden;
        &.views-field-nid{
        width: 50px;

        overflow: hidden;
      }
        &:nth-child(2){
          max-width: 200px;
          overflow: hidden;
          max-height: 50px;

        }
      }
    }
    caption {
      font-weight: bold;
      font-size: 20px;
      margin: 1em;
      text-align: left;
      background: gray;
    }
    @media print {
      font-family: "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
      .branding,
      .tabs-secondary,
      .views-exposed-form {
        display: none;
      }
      a[href]:after {
        content: none !important;
      }

      caption{
        font-size: 20pt;
        font-weight: 700;
        background: gray;
        color: white;
      }
    }
}

.role-administrator{
  body{
    margin-top: 80px;
    .main-container{
      margin-top: 80px;
    }
  }
}

.atlwdg-trigger.atlwdg-SUBTLE{
  opacity: 0;
  left: 0!important;
  right: auto!important;
  -webkit-box-shadow: 0 1px 1px 2px $gray-dark;
  -moz-box-shadow: 0 1px 1px 2px  $gray-dark;
  box-shadow: 0 1px 1px 2px  $gray-dark;
  -webkit-transition: opacity 1s;
  -moz-transition: opacity 1s;
  -ms-transition: opacity 1s;
  -o-transition: opacity 1s;
  transition: opacity 1s;
  &:hover,
  &:active,
  &:focus{
    opacity: 1;
    -webkit-transition: opacity 1s;
    -moz-transition: opacity 1s;
    -ms-transition: opacity 1s;
    -o-transition: opacity 1s;
    transition: opacity 1s;
  }
}

.field-name-field-product-brand{
  .form-checkboxes{
    display: flex;
    &>form-item{
      display: inline-block;
    }
  }
}

.page-node-add{
  .content-container{
  max-width: 1260px;
  margin: auto;}
  }

.node-call_to_action-form{
  .ds-2col-stacked-fluid{
    .group-left{
      width: 75%;
      padding-right: 1em;
    }
    .group-right{width: 25%;
      padding-left: 1em;
    }

  }

}

.campaign-urls {
  display: flex;

  .campaign-type{
      font-weight: bold;
  }
  .campaign-copy-button{
    button {
      border: 19px solid red;
    }
  }
  .campaign-view-button{}
}