/*
*  Default settings for Fluke Health
*
*/

/*
_____  _      __ __  __  _    ___      __ __    ___   ____  _     ______  __ __
|     || |    |  |  ||  |/ ]  /  _]    |  |  |  /  _] /    || |   |      ||  |  |
|   __|| |    |  |  ||  ' /  /  [_     |  |  | /  [_ |  o  || |   |      ||  |  |
|  |_  | |___ |  |  ||    \ |    _]    |  _  ||    _]|     || |___|_|  |_||  _  |
|   _] |     ||  :  ||     \|   [_     |  |  ||   [_ |  _  ||     | |  |  |  |  |
|  |   |     ||     ||  .  ||     |    |  |  ||     ||  |  ||     | |  |  |  |  |
|__|   |_____| \__,_||__|\_||_____|    |__|__||_____||__|__||_____| |__|  |__|__|
*/




$action-color                           : $blue;
$action-color-highlight                 : darken($blue, .25);
$special-action-color                   : $teal;
$special-action-color-highlight         : darken($teal, .25);

/*
*   COLORS
*/

$base-font-color              : $gray;
$color-primary                : $blue;              // ** Primary Action buttons **        ** Special action color (other things) **
$color-secondary              : $blue;         // ** Secondary Action buttons **      ** Action Color (links) **
$color-tertiary               : $color-primary;
$color-pop                    : $yellow;
$base-background-color        : $white;
$background-color-darkest     : $gray-21;             // Navigation
$background-color-dark        : $gray-dark;           // Footer first, Navigation Hover, Search initial
$background-color-mid         : $gray-medium;         // Search hover
$background-color-light       : $gray-light;
$background-color-very-light  : $gray-very-light;    // Sidebars
$heading-font-color           : $base-font-color;
$landing-font-color           : #707070;

$light-color-primary          : transparentize($blue, .95);

/*
*   FONTS
*/

//$base-font-family                     : $helvetica;
//$heading-font-family                  : $roboto;

//$font-family-base                     : $base-font-family;
//$font-family-heading                  : $font-family-base;

//$font-size-base                       : 16px;

$body-background                      : $white;
$body-text                            : $base-font-color;
$body-text-special                    : $black;
$base-link-color                      : $color-primary;

$country-picker-inactive              : rgba($black, .5);
$country-picker-active-desk           : linear-gradient(lighten($background-color-darkest, 5%), $background-color-darkest);
$country-picker-active-mobile         : $background-color-darkest;
$country-picker-text                  : $white;
$country-picker-text-active           : $action-color;

$nav-primary-background               : $section-test;   //$background-color-darkest;
$nav-primary-background-hover         : $gray-very-dark;
$nav-primary-text                     : $white;
$nav-primary-text-special             : $special-action-color;

$nav-secondary-background             : $section-test;   //$background-color-dark;
$nav-secondary-text                   : $base-font-color;  //$white
$nav-secondary-text-special           : $special-action-color;

$footer-primary-background            : $gray-darker;
$footer-primary-text                  : $white;
$footer-primary-text-heading          : $white;
$footer-primary-text-special          : $special-action-color;

$footer-secondary-background          : $background-color-darkest;
$footer-secondary-text                : $white;
$footer-secondary-text-heading        : $gray-light;
$footer-secondary-text-special        : $special-action-color;

$footer-background                    : $gray-darker;
$footer-color                         : $footer-primary-text;
$footer-link-color                    : transparentize($footer-color, 0.6);
$footer-disclaimer-color              : transparentize($footer-color, 0.35);

$sidebar-background                   : $gray-light;
$sidebar-text                         : $black;
$sidebar-special-text                 : $action-color;

$products-section-background          : $gray-light;
$products-section-text                : $black;

$alert-warning                        : $yellow;
$alert-error                          : $red;
$alert-success                        : $green;


$mmenu-background   :  $gray-medium;
$mmenu-text         : $gray-lightest;