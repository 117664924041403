/***************************************************
*
* Here is how the colors are working...
* All of the color values are given a name.
* The names are then associated with human-rememberable names
*
* Then the colors are associated with semantic, smacksy names
*
* SET THE COLORS FOR EACH BRAND IN THE BRAND-SETTINGS-BRAND.SCSS FILE
*
****************************************************/


//BASE COLORS
//Color Assignments
//Shades of grey

$black                : #000;
$gray-21              : #212121;     // aka   $gray-almost-black  @fluke-body-text
$gray-28              : #282828;     // aka   $gray-darkest       @fluke-body-text
$gray-3               : #333;        // aka   $gray-very-dark     @fluke-button-hover
$gray-4               : #444;        // aka   $gray-dark
$gray-5               : #555;        // aka   $gray-dark
$gray                 : $gray-5;    //  aka   $gray-medium        @fluke-menu-item
$gray-6               : #6f6f6f;    //  aka   $gray-medium        @fluke-menu-item
$gray-9               : #999;        // aka   $
$gray-a               : #a7a7a7;    //  aka   $gray-light         @fluke-gray-links
$gray-c               : #ededee;    //  aka   $gray-lighter       @fluke-lines-and-pipes
$gray-d               : #ededee;    //  aka   $gray-lightest      @fluke-sidebar-background
$gray-e               : #f5f5f7;    //  aka   $gray-lightest      @fluke-background
$gray-f               : #f7f7f7;    //  aka   $gray-almost-white  @fluke-box-shadows
$white                : #fff;

// Primary Colors
$teal                 : #72cee4;
$blue                 : #007FEB;
$purple               : #414169;


// Secondary colors
$teal-light       : #7FC4FD;
$blue-light       : #9bc6e9;
$purple-light     : #929dc8;

// Tertiary colors
$yellow        : #ffc72c;
$fluke-yellow  : $yellow;
$yellow-light  : #ffdc85;

// Reds
$red                  : red;
// Oranges
$orange               : orange;

// Yellows
$yellow               : #ffc20e;                //main palette yellow - primary brand color

// Greens
$green                : green;

// Blues
//$blue                 : #477dca;
//$blue-medium          : #035094; //blue
//$blue-dark            : #003b78; //darker blue
//$blue-raysafe         : #4cacc2; //Raysafe Aqua color

// Violets

//HUMAN REMEMBERABLE COLOR NAMES for common base colors

//millions of grays
$black                : $black;
$gray-almost-black    : $gray-21;
$gray-darkest         : $gray-28;
$gray-darker          : $gray-3;
$gray-lighter         : $gray-d;
$gray-lightest        : $gray-e;
$gray-almost-white    : $gray-f;
$white                : $white;


$gray-almost-white    : $gray-f;
$gray-extra-light     : $gray-e;        // stop using this one
$gray-very-light      : $gray-e;
$gray-light           : $gray-c;
$gray-medium          : $gray-6;
$gray-dark            : $gray-3;
$gray-very-dark       : $gray-21;
$box-shadows          : $gray-f;
$background           : $gray-e;
$sidebar-background   : $gray-d;
$lines-and-pipes      : $gray-d;
$button-hover         : #333;
$body-text            : #222;
$inverse-bg           : $gray-very-dark;

// Font Colors

$raysafe-blue         : $teal;
$fbc-blue             : $blue;


//== social media colors
$facebook             : #3b5998; //facebook blue
$google-plus          : #dd4c39; //google+ dark red
$instagram            : radial-gradient(circle at 33% 100%, #FED373 4%, #F15245 30%, #D92E7F 62%, #9B36B7 85%, #515ECF); //instagram rainbow circle
$linked-in            : #4875b4; //linkedin blue
$naver                : #3eaf0e; //naver green (Korean search engine)
$reddit               : #ff5700; //reddit orange red
$tmall                : #c40000; //tmall red (Chinese ecommerce platform)
$twitter              : #33ccff; //twitter blue
$vk                   : #507299; //vk blue (Russian Facebook)
$weibo                : #f7671d; //weibo orange (Chinese social network)
$youku                : #2fb3ff; //youku blue (Chinese youtube)
$youtube              : #ff3333; //youtube red



//Testing Colors
%test-background{
background:
  /* On "top" */
repeating-linear-gradient(
                45deg,
                transparent,
                transparent 10px,
                #ccc 10px,
                #ccc 20px),
  /* on "bottom" */
linear-gradient(
                to bottom,
                #eee,
                #999);
}
$section-test          : transparent; // url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAATklEQVQoU2P0XnXYeGuY7VkGKADxQUx0MUaYAhCNrAndALhCdAl0jWCF6CYhWw2TY8SmCOYcmDtBaohXSLTVyMGCHCRYPYNNMUbwEBvgAIdfX7coQ8lfAAAAAElFTkSuQmCC);
$section-test-alt      : transparent; //rgba($gray-light, .5);

$dark-transitional-background : linear-gradient(to bottom,$gray-dark 0, $gray-darkest 100%);
